@use "../figma";
@use "../mixins";

$background-disabled: figma.$component-form-field-checkbox-box-background-disabled;
$background-checked-focus: figma.$component-form-field-checkbox-box-background-checked-focus;
$background-checked-normal: figma.$component-form-field-checkbox-box-background-checked-normal;
$background-checked-pressed: figma.$component-form-field-checkbox-box-background-checked-pressed;
$background-unchecked-focus: figma.$component-form-field-checkbox-box-background-unchecked-focus;
$background-unchecked-normal: figma.$component-form-field-checkbox-box-background-unchecked-normal;
$background-unchecked-pressed: figma.$component-form-field-checkbox-box-background-unchecked-pressed;

$check-disabled: figma.$component-form-field-checkbox-check-disabled;
$check-enabled: figma.$component-form-field-checkbox-check-enabled;

$border-normal: figma.$component-form-field-default-border-normal;
$border-focus: figma.$component-form-field-default-border-focus;

.checkbox {
  display: inline-flex;
  position: relative;
}

.checkbox__input {
  appearance: none;
  outline: none;
  margin: 0;

  cursor: pointer;
  background-color: $background-unchecked-normal;
  border: 1px solid $border-normal;
}

.checkbox__icon {
  display: inline-flex;
  color: $check-enabled;

  @include mixins.absolute-center;

  pointer-events: none;
}

// Size

.checkbox--size-100 {
  .checkbox__input {
    width: var(--component-form-field-checkbox-box-size-100);
    height: var(--component-form-field-checkbox-box-size-100);
    border-radius: var(--default-border-radius-50);
  }
}

.checkbox--size-200 {
  .checkbox__input {
    width: var(--component-form-field-checkbox-box-size-200);
    height: var(--component-form-field-checkbox-box-size-200);
    border-radius: var(--default-border-radius-75);
  }
}

.checkbox--size-300 {
  .checkbox__input {
    width: var(--component-form-field-checkbox-box-size-300);
    height: var(--component-form-field-checkbox-box-size-300);
    border-radius: var(--default-border-radius-100);
  }
}

// Enabled

.checkbox__input:not(:disabled) {
  &:checked,
  &:indeterminate {
    background-color: $background-checked-normal;
  }

  // Focus

  &:focus {
    background-color: $background-unchecked-focus;
    border: 1px solid $border-focus;
    box-shadow: 0px 0px 0px var(--effect-focus-highlight-spread) figma.$effect-focus-highlight-normal;
  }

  &:checked:focus,
  &:indeterminate:focus {
    background-color: $background-checked-focus;
  }

  // Pressed

  &:active {
    background-color: $background-unchecked-pressed;
  }

  &:checked:active,
  &:indeterminate:active {
    background-color: $background-checked-pressed;
  }
}

// Disabled

.checkbox__input:disabled {
  cursor: not-allowed;
  background-color: $background-disabled;

  & + .checkbox__icon {
    color: $check-disabled;
  }
}
