@use "../figma";
@use "../mixins";
@use "../typography";

$link-gap: figma.$spacer-50;
$link-color-disabled: figma.$component-link-disabled-foreground;
$link-color-primary: figma.$component-link-primary-foreground-normal;
$link-color-primary-hover-focus: figma.$component-link-primary-foreground-hover;
$link-color-primary-active: figma.$component-link-primary-foreground-pressed;
$link-color-danger: figma.$component-link-danger-normal;
$link-color-danger-hover-focus: figma.$component-link-danger-hover;
$link-color-danger-active: figma.$component-link-danger-pressed;
$link-color-soft: figma.$component-link-default-soft-foreground-normal;
$link-color-soft-hover-focus: figma.$component-link-default-soft-foreground-hover;
$link-color-soft-active: figma.$component-link-default-soft-foreground-pressed;

.link {
  text-decoration: none;

  @include mixins.inline-horizontal($link-gap);
  flex-wrap: nowrap;

  cursor: pointer
}

.link__text {
  white-space: nowrap;
}

// Hover/Focus

.link {
  &:hover, &:focus {
    .link__text {
      text-decoration: underline;
    }
  }
}

.link--disabled {
  cursor: not-allowed;

  &:hover, &:focus {
    .link__text {
      text-decoration: none;
    }
  }
}

// Color

.link--color-disabled {
  color: $link-color-disabled;
}

.link--color-primary {
  color: $link-color-primary;

  &:hover, &:focus {
    color: $link-color-primary-hover-focus;
  }

  &:active {
    color: $link-color-primary-active;
  }
}

.link--color-danger {
  color: $link-color-danger;

  &:hover, &:focus {
    color: $link-color-danger-hover-focus;
  }

  &:active {
    color: $link-color-danger-active;
  }
}

.link--color-soft {
  color: $link-color-soft;

  &:hover, &:focus {
    color: $link-color-soft-hover-focus;
  }

  &:active {
    color: $link-color-soft-active;
  }
}

// Typography

.link--typography-body-100 {
  @include typography.body-100(var(--typography-link-body-weight));
}

.link--typography-body-200 {
  @include typography.body-200(var(--typography-link-body-weight));
}

.link--typography-body-300 {
  @include typography.body-300(var(--typography-link-body-weight));
}

.link--typography-code-100 {
  @include typography.code-100(var(--typography-link-code-weight));
}

.link--typography-overline {
  @include typography.overline;
}

// Inert

.link--inert {
  cursor: default;

  &:hover, &:focus {
    .link__text {
      text-decoration: none;
    }

    &.link--color-disabled {
      color: $link-color-disabled;
    }

    &.link--color-primary {
      color: $link-color-primary;
    }

    &.link--color-danger {
      color: $link-color-danger;
    }

    &.link--color-soft {
      color: $link-color-soft;
    }
  }
}

// Clickable links

.link--is-clickable {
  background-color: unset;
  border: 0;
  padding: 0;
}
