@use "../effects";
@use "../figma";
@use "../mixins";

.v2-nav {
  background-color: figma.$default-background-normal;
  box-shadow: effects.$box-shadow-100;

  &__bar {
    @include mixins.horizontal(var(--component-navigation-content-spacing));

    height: 60px;
    padding: var(--component-navigation-padding-y) var(--layout-gutter);
  }

  &__start {
    display: flex;
  }

  &__end {
    margin-left: auto;
    @include mixins.horizontal(var(--component-navigation-content-spacing));
  }

  &__inline-menu {
    @include mixins.horizontal(var(--component-navigation-content-spacing));
  }

  &__toggle {
    position: relative;
  }

  &__popup-menu {
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;

    position: absolute;
    top: 0;
    right: 0;

    transition: transform 160ms 0ms ease-out, opacity 160ms 0ms ease-out;
  }

  &--is-open &__popup-menu {
    opacity: 1;
    transform: scale(1);
  }

  &--is-open &__toggle > .v2-nav-menu-button {
    visibility: hidden;
  }
}
