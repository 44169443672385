@use "../effects";
@use "../figma";
@use "../mixins";
@use "../typography";

.metric-card {
  background-color: figma.$default-background-normal;
  padding: figma.$spacer-300 var(--layout-gutter);
  @include mixins.vertical(figma.$spacer-150);

  border-radius: var(--default-border-radius-200);
  box-shadow: effects.$box-shadow-100;

  min-width: min-content;
}

/*hn*/.metric-card__title {
  // Reset
  margin: 0;

  // Typography
  @include typography.metric;
}

.metric-card__content {
  @include mixins.horizontal(8px);
}

.metric-card__name {
  @include typography.overline;
  color: figma.$default-foreground-soft;
}

.metric-card__icon {
  margin-left: auto;
}
