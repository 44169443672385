@use "sass:meta";
@use "../figma";
@use "../mixins";
@use "../typography";

.heading {
  @include mixins.vertical(figma.$spacer-100);
}

.heading__header {
  @include mixins.horizontal(figma.$spacer-150, $align-items: flex-start);
}

.heading__icon {
  display: flex;
  align-items: center;
}

.heading__title {
  margin: 0;
}

.heading__subheading {
  color: figma.$default-foreground-normal;
}

@for $i from 1 through 6 {
  .heading--size--#{$i}00 {
    .heading__icon {
      height: var(--typography-heading-#{$i}00-line-height);
    }

    .heading__title {
      @include meta.apply(meta.get-mixin("heading-#{$i}00", "typography"));
    }

    .heading__subheading {
      @if $i <= 3 {
        @include typography.body-300;
      } @else {
        @include typography.body-200;
      }
    }
  }
}

.heading__subheading--color-soft {
  color: figma.$default-foreground-soft;
}

// Utility

.subheading-success {
  @include typography.body-200($font-weight: var(--typography-body-weight-bold));
  color: figma.$default-foreground-success;
}

.subheading-error {
  @include typography.body-200($font-weight: var(--typography-body-weight-bold));
  color: figma.$default-foreground-error;
}

// Alignment

.heading--alignment--left {
  .heading__title,
  .heading__subheading {
    @include mixins.max-content-width;
  }
}

.heading--alignment--center {
  text-align: center;

  .heading__header {
    justify-content: center;
  }
}
