@use "../figma";

.nav-menu-button {
  border: 0;
  padding: 0;
  background-color: transparent;

  color: figma.$component-link-primary-foreground-normal;
  cursor: pointer;

  &:hover, &:focus {
    color: figma.$component-link-primary-foreground-hover;
  }

  &:active {
    color: figma.$component-link-primary-foreground-pressed;
  }
}
