/* Mode 1 */
/* number */
$spacer-25: 2px;
$spacer-50: 4px;
$spacer-75: 6px;
$spacer-100: 8px;
$spacer-125: 10px;
$spacer-150: 12px;
$spacer-200: 16px;
$spacer-250: 20px;
$spacer-300: 24px;
$spacer-350: 28px;
$spacer-400: 32px;
$spacer-500: 40px;
$spacer-600: 48px;
$spacer-700: 56px;
$spacer-800: 64px;
$spacer-900: 72px;
$spacer-1000: 80px;
$spacer-1100: 88px;
$spacer-1200: 96px;
$spacer-1400: 112px;
$spacer-1600: 128px;
