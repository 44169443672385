@use "../figma";
@use "../mixins";
@use "../typography";

.modal {
  @include mixins.vertical(var(--component-modal-spacing-content));

  padding: var(--component-modal-padding-content-y) var(--layout-gutter);

  width: var(--component-modal-max-width);
  max-width: var(--component-modal-max-width);

  border-radius: var(--default-border-radius-300);
  background-color: figma.$default-background-normal;
  color: figma.$default-foreground-normal;
}

.modal__heading {
  @include mixins.vertical(var(--component-modal-spacing-text));
}

.modal__title {
  margin: 0;

  @include typography.heading-400;
}

.modal__content {
  margin: 0;

  @include typography.body-200;

  .warning {
    font-weight: var(--typography-body-weight-bold);
    color: figma.$default-foreground-error;
  }
}

.modal__actions {
  @include mixins.horizontal(var(--component-modal-spacing-actions));
  justify-content: flex-end;

  padding-top: var(--component-modal-padding-actions-top);
}
