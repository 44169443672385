@use "../device";
@use "../effects";
@use "../figma";
@use "../mixins";
@use "../typography";

.card__content {
  padding-left: var(--layout-gutter);
  padding-right: var(--layout-gutter);

  @include mixins.max-content-width;
}

.card__header {
  @include mixins.horizontal(figma.$spacer-100, $align-items: flex-start);

  .icon--size-200 {
    height: var(--typography-heading-400-line-height);
  }
}

.card__title {
  @include typography.heading-400;
}

.card__description {
  margin: figma.$spacer-100 0 0 0;
  @include typography.body-200;
  color: figma.$default-foreground-soft;
}

.card__badge {
  margin-top: figma.$spacer-200;
}

.card__data {
  width: 100%;
  overflow-x: auto;

  padding-left: var(--layout-gutter);
  padding-right: var(--layout-gutter);
}

.card__actions-container {
  overflow-x: auto;
  display: flex;
  flex-direction: row-reverse;
}

.card__actions {
  @include mixins.horizontal(figma.$spacer-250);
  justify-content: flex-end;

  padding-left: var(--layout-gutter);
  padding-right: var(--layout-gutter);
}


.card--has-gradient::after {
  content: "";
  
  position: absolute;
  z-index: -1;

  width: var(--component-gradient-size-200);
  max-width: var(--component-gradient-size-200);
  height: var(--component-gradient-size-200);
  max-height: var(--component-gradient-size-200);
}

.card--gradient {
  &-blue::after {
    background: effects.$radial-gradient-blue-100;
  }
  
  &-green::after {
    background: effects.$radial-gradient-green-100;
  }
  
  &-yellow::after {
    background: effects.$radial-gradient-yellow-100;
  }

  &-purple::after {
    background: effects.$radial-gradient-purple-100;
  }

  &-red::after {
    background: effects.$radial-gradient-red-100;
  }
}

@include device.eq-100 {
  .card--has-gradient::after {
    display: none;
  }
}

@include device.eq-200 {
  .card--gradient-left::after {
    left: -480px;
    bottom: -240px;
  }

  .card--gradient-right::after {
    right: -240px;
    bottom: -240px;
  }
}

@include device.eq-300 {
  .card--gradient-left::after {
    left: -480px;
    bottom: -480px;
  }

  .card--gradient-right::after {
    right: -480px;
    bottom: -480px;
  }
}

@include device.eq-400 {
  .card--gradient-left::after {
    left: -480px;
    bottom: -640px;
  }

  .card--gradient-right::after {
    right: -640px;
    bottom: -640px;
  }
}


.card__children {
  @include mixins.vertical(var(--layout-app-body-section-spacing));

  z-index: 2;

  width: 100%;
}

.card__fragments {
  overflow: hidden;
  
  width: 100%;
  height: 100%;
  
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  border-radius: var(--default-border-radius-300);
}

@include device.eq-100 {
  .card__fragments {
    display: none;
  }
}

.card {
  padding: var(--layout-app-body-section-padding-top) 0 var(--layout-app-body-section-padding-bottom) 0;
  display: flex;

  position: relative;

  box-shadow: effects.$box-shadow-200;
  background: figma.$default-background-normal;
  border: 1px solid figma.$default-border;
  border-radius: var(--default-border-radius-300);
}
