@use "../device";
@use "../mixins";

.my-rewards,
.my-rewards__cards {
  @include mixins.vertical(var(--layout-app-section-spacer-400));
}

@include device.gt-200 {
  .my-rewards__cards {
    flex-direction: row;
    align-items: stretch;
    gap: var(--layout-app-section-spacer-300);

    & > * {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
