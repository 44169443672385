@use "../device";
@use "../figma";
@use "../mixins";
@use "../typography";

$footer-container-max-width: device.$max-width;
$footer-border-color: figma.$default-border;
$footer-copyright-color: figma.$default-foreground-soft;

.footer {
  --footer-gap: var(--component-footer-content-spacing);
  --footer-container-vertical-padding: var(--component-footer-padding-top);
  --footer-container-horizontal-padding: var(--layout-gutter);

  display: flex;
  justify-content: center;

  border-top: 1px solid $footer-border-color;

  background-color: figma.$default-background-normal;
}

.footer__container {
  @include mixins.vertical(var(--footer-gap));

  padding: var(--footer-container-vertical-padding) var(--footer-container-horizontal-padding);

  width: 100%;
  max-width: $footer-container-max-width;
}

.footer__links {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include mixins.inline-horizontal(var(--footer-gap));
  flex-wrap: nowrap;
}

.footer__copyright {
  margin: 0;

  @include typography.body-100;
  white-space: nowrap;

  color: $footer-copyright-color;
}

.footer--horizontal .footer__container {
  flex-direction: row-reverse;
}

.footer--horizontal .footer__copyright {
  margin-right: auto;
}

@include device.gt-100 {
  .footer--is-responsive .footer__container {
    flex-direction: row-reverse;
  }

  .footer--is-responsive .footer__copyright {
    margin-right: auto;
  }
}
