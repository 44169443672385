// Font families

$-fira-mono: "Fira Mono", mono;
$-open-sans: "Open Sans", sans-serif;
$-poppins: "Poppins", serif;

// Font weights

$-normal: 400;
$-medium: 500;
$-semi-bold: 600;
$-bold: 700;

// Typography

@mixin body-100($font-weight: var(--typography-body-weight-normal)) {
  @include typography(
    $-open-sans,
    var(--typography-body-100-size),
    var(--typography-body-100-line-height),
    var(--typography-body-100-letter-spacing),
    $font-weight
  );
}

@mixin body-200($font-weight: var(--typography-body-weight-normal)) {
  @include typography(
    $-open-sans,
    var(--typography-body-200-size),
    var(--typography-body-200-line-height),
    var(--typography-body-200-letter-spacing),
    $font-weight
  );
}

@mixin body-300($font-weight: var(--typography-body-weight-normal)) {
  @include typography(
    $-open-sans,
    var(--typography-body-300-size),
    var(--typography-body-300-line-height),
    var(--typography-body-300-letter-spacing),
    $font-weight
  );
}

@mixin code-100($font-weight: var(--typography-code-weight-normal)) {
  @include typography(
    $-fira-mono,
    var(--typography-code-100-size),
    var(--typography-code-100-line-height),
    var(--typography-code-100-letter-spacing),
    $font-weight
  );
}

@mixin code-200($font-weight: var(--typography-code-weight-normal)) {
  @include typography(
    $-fira-mono,
    var(--typography-code-200-size),
    var(--typography-code-200-line-height),
    var(--typography-code-200-letter-spacing),
    $font-weight
  );
}

@mixin code-300($font-weight: var(--typography-code-weight-normal)) {
  @include typography(
    $-fira-mono,
    var(--typography-code-300-size),
    var(--typography-code-300-line-height),
    var(--typography-code-300-letter-spacing),
    $font-weight
  );
}

@mixin heading-100 {
  @include typography(
    $-poppins,
    var(--typography-heading-100-size),
    var(--typography-heading-100-line-height),
    var(--typography-heading-100-letter-spacing),
    var(--typography-heading-weight)
  );
}

@mixin heading-200 {
  @include typography(
    $-poppins,
    var(--typography-heading-200-size),
    var(--typography-heading-200-line-height),
    var(--typography-heading-200-letter-spacing),
    var(--typography-heading-weight)
  );
}

@mixin heading-300 {
  @include typography(
    $-poppins,
    var(--typography-heading-300-size),
    var(--typography-heading-300-line-height),
    var(--typography-heading-300-letter-spacing),
    var(--typography-heading-weight)
  );
}

@mixin heading-400 {
  @include typography(
    $-poppins,
    var(--typography-heading-400-size),
    var(--typography-heading-400-line-height),
    var(--typography-heading-400-letter-spacing),
    var(--typography-heading-weight)
  );
}

@mixin heading-500 {
  @include typography(
    $-poppins,
    var(--typography-heading-500-size),
    var(--typography-heading-500-line-height),
    var(--typography-heading-500-letter-spacing),
    var(--typography-heading-weight)
  );
}

@mixin heading-600 {
  @include typography(
    $-poppins,
    var(--typography-heading-600-size),
    var(--typography-heading-600-line-height),
    var(--typography-heading-600-letter-spacing),
    var(--typography-heading-weight)
  );
}

@mixin metric {
  @include typography(
    $-fira-mono,
    var(--typography-metric-size),
    var(--typography-metric-line-height),
    var(--typography-metric-letter-spacing),
    var(--typography-metric-weight)
  );
}

@mixin overline($font-weight: var(--typography-overline-weight)) {
  @include typography(
    $-fira-mono,
    var(--typography-overline-size),
    var(--typography-overline-line-height),
    var(--typography-overline-letter-spacing),
    $font-weight,
    $is-uppercase: true
  );
}

@mixin data-key {
  @include typography($-fira-mono, 13px, 20px, 0px, $-normal, $is-uppercase: true);
}

@mixin data-value-primary {
  @include typography($-fira-mono, 13px, 20px, 0px, $-bold, $is-uppercase: true);
}

@mixin data-value-secondary {
  @include typography($-fira-mono, 13px, 20px, 0px, $-normal, $is-uppercase: true);
}

@mixin label {
  @include typography(
    $-fira-mono,
    var(--typography-form-field-label-size),
    var(--typography-form-field-label-line-height),
    var(--typography-form-field-label-letter-spacing),
    var(--typography-form-field-label-weight),
    $is-uppercase: true
  );
}

@mixin support-text($font-weight: var(--typography-form-field-support-weight-normal)) {
  @include typography(
    $-open-sans,
    var(--typography-form-field-support-100-size),
    var(--typography-form-field-support-100-line-height),
    var(--typography-form-field-support-200-letter-spacing),
    $font-weight
  )
}

@mixin action-selector {
  @include typography(
    $-fira-mono,
    var(--typography-form-field-action-selector-size),
    var(--typography-form-field-action-selector-line-height),
    var(--typography-form-field-action-selector-letter-spacing),
    var(--typography-form-field-action-selector-weight),
    $is-uppercase: true
  )
}


@mixin typography(
  $font-family,
  $font-size,
  $line-height,
  $letter-spacing,
  $font-weight,
  $is-uppercase: false
) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-weight: $font-weight;

  @if $is-uppercase {
    text-transform: uppercase;
  }
}
