@use "../device";
@use "../figma";
@use "../mixins";

.nav-menu .link {
  &:hover, &:focus {
    .link__text {
      text-decoration: none;
    }
  }
}

.nav-menu__group {
  @include mixins.vertical(figma.$spacer-300);
  align-items: flex-end;

  padding: figma.$spacer-300 var(--layout-gutter);
  border-bottom: 1px solid figma.$default-border;
}

@mixin horizontal-variant {
  @include mixins.horizontal(var(--component-navigation-content-spacing));

  .nav-menu__group {
    flex-direction: row;
    align-items: center;
    gap: var(--component-navigation-content-spacing);

    padding: 0;
    border: 0;

    &:last-child {
      margin-left: auto;
    }
  }

  .link {
    border-top: var(--effect-shadow-100-x) solid figma.$constant-transparent;
    border-bottom: var(--effect-shadow-100-x) solid figma.$constant-transparent;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.nav-menu--is-horizontal {
  @include horizontal-variant;
}

@include device.gt-200 {
  .nav-menu--is-responsive {
    @include horizontal-variant;
  }
}

.nav-menu__item--is-active {
  .link {
    border-top: var(--effect-shadow-100-x) solid figma.$constant-transparent;
    border-bottom: var(--effect-shadow-100-x) solid figma.$default-blue-300;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .link--disabled {
    border-bottom: var(--effect-shadow-100-x) solid figma.$component-link-disabled-foreground;
  }
}
