@use "../figma";
@use "../typography";

.label-text {
  color: figma.$default-foreground-normal;
}

// Sizes

.label-text--size-100 {
  @include typography.body-100;
}

.label-text--size-200 {
  @include typography.body-200;
}

// Validity

.label-text--is-valid {
  color: figma.$default-foreground-success;
}

.label-text--is-invalid {
  color: figma.$default-foreground-error;
}
