@use "figma";

$box-shadow-100: var(--effect-shadow-100-x) var(--effect-shadow-100-y) var(--effect-shadow-100-blur) var(--effect-shadow-100-spread) figma.$effect-shadow-100;
$box-shadow-200: var(--effect-shadow-200-x) var(--effect-shadow-200-y) var(--effect-shadow-200-blur) var(--effect-shadow-200-spread) figma.$effect-shadow-200;
$box-shadow-300: var(--effect-shadow-300-x) var(--effect-shadow-300-y) var(--effect-shadow-300-blur) var(--effect-shadow-300-spread) figma.$effect-shadow-300;

$linear-gradient-yellow-200: linear-gradient(90deg, figma.$default-yellow-300 0%, figma.$default-yellow-100 98.68%);

$radial-gradient-blue-100: radial-gradient(50% 50% at 50% 50%, figma.$default-blue-50 0%, figma.$default-blue-25-0 100%);
$radial-gradient-green-100: radial-gradient(50% 50% at 50% 50%, figma.$default-green-50 0%, figma.$default-green-25-0 100%);
$radial-gradient-yellow-100: radial-gradient(50% 50% at 50% 50%, figma.$default-yellow-50 0%, figma.$default-yellow-25-0 100%);
$radial-gradient-purple-100: radial-gradient(50% 50% at 50% 50%, figma.$default-purple-50 0%, figma.$default-purple-25-0 100%);
$radial-gradient-red-100: radial-gradient(50% 50% at 50% 50%, figma.$default-red-50 0%, figma.$default-red-25-0 100%);
