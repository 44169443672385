@use "../device";
@use "../effects";
@use "../figma";
@use "../mixins";

.v2-nav-alt {
  background-color: figma.$default-background-normal;
  border-bottom: 1px solid figma.$default-border;
  box-shadow: effects.$box-shadow-100;

  white-space: nowrap;
  min-width: max-content;

  &__content {
    height: 60px;
    padding: var(--component-navigation-padding-y) var(--layout-gutter);

    @include mixins.horizontal(var(--component-navigation-content-spacing));
    justify-content: space-between;
  }

  &__start {
    display: flex;
  }

  &__end {
    @include mixins.horizontal(var(--component-navigation-content-spacing));
  }

  &__expandable-secondary {
    display: none;
  }

  &__expandable-primary {
    display: none;
  }

  &__toggle {
    position: relative;
  }

  &__close-area {
    position: absolute;
    top: 0;
    right: 0;

    visibility: hidden;
    opacity: 0;
    transform-origin: top right;
    transform: scale(0.8);
    transition: visibility 0ms 160ms ease-out, opacity 160ms 0ms ease-out, transform 160ms 0ms ease-out;
  }

  &--is-open &__close-area {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    transition-delay: 0ms;
  }

  &--is-open &__open-area {
    visibility: hidden;
  }
}

/*
 * If the close area is empty then we don't want to show the
 * toggle element at all.
 */
.v2-nav-alt__toggle:has(.v2-nav-alt__close-area:empty) {
  display: none;
}

/*
 * Only show the expandable secondary element on devices 300 and above.
 */
@include device.gt-200 {
  .v2-nav-alt__expandable-secondary {
    @include mixins.horizontal(var(--component-navigation-content-spacing));
  }
}

/*
 * Only show the expandable primary element on devices 200 and above.
 */
@include device.gt-100 {
  .v2-nav-alt__expandable-primary {
    display: flex;
  }
}

/*
 * By default the navigation is opaque but it can be transparent as well.
 */
.v2-nav-alt--is-transparent {
  background-color: transparent;
  border-bottom-color: transparent;
  box-shadow: none;
}
