@use "../device";
@use "../figma";
@use "../typography";

$data-key-primary-color: figma.$default-foreground-soft;
$data-key-secondary-color: figma.$default-foreground-soft;
$data-value-primary-color: figma.$default-foreground-normal;
$data-value-secondary-color: figma.$default-foreground-normal;
$data-grid-row-gap: figma.$spacer-50;
$data-grid-column-gap: figma.$spacer-200;
$data-grid-separator-row-gap: calc(var(--layout-app-section-spacer-200) - 2 * #{$data-grid-row-gap});

// Data Key/Value

.data-key {
  @include typography.data-key;
  white-space: nowrap;

  color: $data-key-primary-color;
}

.data-key--secondary {
  color: $data-key-secondary-color;
}

.data-value {
  @include typography.data-value-primary;
  white-space: nowrap;

  color: $data-value-primary-color;
}

.data-value--secondary {
  @include typography.data-value-secondary;

  color: $data-value-secondary-color;
}

// Data Grid

.data-grid {
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: $data-grid-row-gap $data-grid-column-gap;
}

.data-grid__group {
  display: contents;
}

.data-grid__group-separator {
  grid-column: 1 / span 2;
  margin-top: $data-grid-separator-row-gap;
}

@include device.gt-100 {
  .data-grid {
    display: flex;
    flex-wrap: wrap;
    gap: var(--layout-app-section-spacer-200);
  }

  .data-grid__group {
    display: inline-grid;
    grid-auto-rows: min-content;
    grid-template-columns: auto auto;
    gap: $data-grid-row-gap $data-grid-column-gap;
  }

  .data-grid__group-separator {
    display: none;
  }
}
