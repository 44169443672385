@use "../figma";
@use "../mixins";
@use "../typography";

.badge {
  @include mixins.inline-horizontal(figma.$spacer-50);
  padding: 2px figma.$spacer-100 2px figma.$spacer-75;
  border-radius: var(--default-border-radius-75);
  background-color: figma.$component-badge-purple-background;
}

.badge__text {
  @include typography.code-100(var(--typography-code-weight-bold));
  color: figma.$component-badge-purple-foreground;
  white-space: nowrap;
}
