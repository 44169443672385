@use "../figma";
@use "../mixins";
@use "../typography";

.v2-nav-menu-button {
  border: 0;

  @include mixins.horizontal(figma.$spacer-50);
  padding: figma.$spacer-100 figma.$spacer-200;

  border-radius: 18px;
  background-color: figma.$component-button-nav-menu-open-background-normal;

  @include typography.overline(var(--typography-button-overline-weight));

  color: figma.$component-button-nav-menu-open-foreground;
  cursor: pointer;

  &:hover {
    background-color: figma.$component-button-nav-menu-open-background-hover;
  }

  &:active {
    background-color: figma.$component-button-nav-menu-open-background-pressed;
  }

  &--is-open {
    justify-content: flex-end;

    background-color: figma.$component-button-nav-menu-close-background-normal;
    color: figma.$component-button-nav-menu-close-foreground;

    &:hover {
      background-color: figma.$component-button-nav-menu-close-background-hover;
    }

    &:active {
      background-color: figma.$component-button-nav-menu-close-background-pressed;
    }
  }
}
