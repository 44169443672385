@use "../device";
@use "../effects";
@use "../figma";
@use "../mixins";
@use "../typography";

.metrics {
  min-width: min-content;
  background: effects.$linear-gradient-yellow-200;
}

.metrics__wrapper {
  @include mixins.vertical(var(--layout-app-body-section-margin-heading-bottom));
  padding: var(--layout-app-body-section-spacing) var(--layout-gutter);

  width: 100%;
  max-width: device.$max-width;
  margin-left: auto;
  margin-right: auto;
}

.metrics__metric-cards {
  @include mixins.vertical(var(--layout-gutter));
}

@include device.gt-200 {
  .metrics__metric-cards {
    flex-direction: row;

    .metric-card {
      flex-basis: 100%;
    }
  }
}
