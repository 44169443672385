@use "../figma";
@use "../mixins";

$fragment-offset-margin-x: 56px;
$fragment-slanted-short-visible-height: 100px;
$fragment-slanted-long-visible-height: 200px;
$fragment-part-width: 95px;
$fragment-part-bullnose-height: 95px;
$fragment-part-concave-above-height: 47px;
$fragment-part-concave-below-height: 45px;

.fragment {
  position: absolute;
  z-index: 0;

  &--is-split {
    @include mixins.vertical(0);

    top: 0;
    bottom: 0;

    &.fragment--is-left {
      left: $fragment-offset-margin-x * -1;
    }

    &.fragment--is-right {
      right: $fragment-offset-margin-x * -1;
    }
  }

  &--is-slanted {
    @include mixins.vertical(0);

    height: 100%;
    top: 100%;

    &.fragment--is-short {
      margin-top: $fragment-slanted-short-visible-height * -1;
      transform-origin: 50% $fragment-slanted-short-visible-height;
    }

    &.fragment--is-long {
      margin-top: $fragment-slanted-long-visible-height * -1;
      transform-origin: 50% $fragment-slanted-long-visible-height;
    }

    &.fragment--is-left {
      left: $fragment-offset-margin-x * -1;
    }

    &.fragment--is-right {
      right: $fragment-offset-margin-x * -1;
    }
  }

  &--is-45-degrees {
    transform: rotate(45deg);
  }

  &--is-90-degrees {
    transform: rotate(0deg);
  }

  &--is-135-degrees {
    transform: rotate(-45deg);
  }

  &__part {
    width: $fragment-part-width;

    &--is-bullnose {
      flex: 0 0 $fragment-part-bullnose-height;
    }

    &--is-concave {
      &.fragment__part--is-above {
        flex: 0 0 $fragment-part-concave-above-height;
      }

      &.fragment__part--is-below {
        flex: 0 0 $fragment-part-concave-below-height;
      }
    }

    &--is-filler {
      flex-grow: 1;
    }

    &--is-blue {
      color: figma.$component-fragment-blue-100;
    }

    &--is-green {
      color: figma.$component-fragment-green-100;
    }

    &--is-orange {
      color: figma.$component-fragment-orange-100;
    }

    &--is-purple {
      color: figma.$component-fragment-purple-100;
    }

    &--is-red {
      color: figma.$component-fragment-red-100;
    }

    &--is-yellow {
      color: figma.$component-fragment-yellow-100;
    }
  }
}
