@use "figma";

//
// Universal box sizing, see https://css-tricks.com/box-sizing/#aa-universal-box-sizing.
//
*, *:before, *:after {
  box-sizing: border-box;
}

//
// Animations
//
.animation-spin {
  animation-name: animation-spin;
  animation-duration: 400ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
}

@keyframes animation-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

//
// Dynamic Text
//
:root {
  --dynamic-text-color: #{figma.$default-foreground-success};
}

//
// Classes
//
.html, .body, .html body {
  margin: 0;
}

.html {
  height: 100vh;
}

.body, .html body {
  min-width: 320px;
  height: 100%;
}
