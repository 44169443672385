@use "../figma";
@use "../typography";

.range-button {
  appearance: none;
  margin: 0;
  outline: none;

  display: inline-flex;
  @include typography.action-selector;

  border: 1px solid figma.$component-form-field-action-border-normal;
  border-radius: 2px;
  padding: 1px 2px;

  color: figma.$component-form-field-action-foreground-normal;
  background: figma.$component-form-field-action-background-normal;
  box-shadow: var(--effect-shadow-200-x) var(--effect-shadow-200-y) var(--effect-shadow-200-blur) var(--effect-shadow-200-spread) figma.$effect-shadow-100;

  cursor: pointer;

  &:hover {
    color: figma.$component-form-field-action-foreground-hover;
  }
}

.range-button[disabled] {
  cursor: default;

  &:hover {
    color: figma.$component-form-field-action-foreground-normal;
  }
}

.range-button--is-active {
  color: figma.$component-form-field-action-foreground-active;
  border-color: figma.$component-form-field-action-border-active;
  background: figma.$component-form-field-action-background-active;
  cursor: default;

  &[disabled]:hover, &:hover {
    color: figma.$component-form-field-action-foreground-active;
  }
}
