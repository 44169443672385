@use "device";
@use "figma";

:root {
  --component-footer-content-spacing: #{figma.$spacer-150};
  --component-footer-padding-top: #{figma.$spacer-200};
  --component-footer-padding-bottom: #{figma.$spacer-250};
  --component-form-field-checkbox-box-size-100: 20px;
  --component-form-field-checkbox-box-size-200: 25px;
  --component-form-field-checkbox-box-size-300: 30px;
  --component-form-field-dropdown-caret-height: 6px;
  --component-form-field-dropdown-caret-margin-left: #{figma.$spacer-100};
  --component-form-field-dropdown-caret-width: 10px;
  --component-form-field-switch-handle-diameter-100: 14px;
  --component-form-field-switch-handle-diameter-200: 17px;
  --component-form-field-switch-handle-diameter-300: 20px;
  --component-form-field-switch-padding-100: 3px;
  --component-form-field-switch-padding-200: 4px;
  --component-form-field-switch-padding-300: 5px;
  --component-form-field-switch-track-height-100: 20px;
  --component-form-field-switch-track-height-200: 25px;
  --component-form-field-switch-track-height-300: 30px;
  --component-form-field-switch-track-width-100: 34px;
  --component-form-field-switch-track-width-200: 42px;
  --component-form-field-switch-track-width-300: 54px;
  --component-fragment-size-100: #{figma.$spacer-600};
  --component-fragment-size-200: #{figma.$spacer-1200};
  --component-fragment-size-300: #{figma.$spacer-1600};
  --component-gradient-size-200: 360px;
  --component-modal-max-width: 480px;
  --component-modal-padding-actions-top: #{figma.$spacer-100};
  --component-modal-padding-content-y: #{figma.$spacer-350};
  --component-modal-spacing-text: #{figma.$spacer-150};
  --component-modal-spacing-actions: #{figma.$spacer-200};
  --component-modal-spacing-content: #{figma.$spacer-300};
  --component-navigation-content-spacing: #{figma.$spacer-150};
  --component-navigation-content-height: 36px;
  --component-navigation-padding-y: #{figma.$spacer-150};
  --component-wallet-icon-icon: 28px;
  --component-wallet-icon-height: 56px;
  --component-wallet-icon-width: 56px;
  --default-border-radius-50: #{figma.$spacer-50};
  --default-border-radius-75: #{figma.$spacer-75};
  --default-border-radius-100: #{figma.$spacer-100};
  --default-border-radius-150: #{figma.$spacer-150};
  --default-border-radius-200: #{figma.$spacer-200};
  --default-border-radius-300: #{figma.$spacer-300};
  --default-border-radius-400: #{figma.$spacer-400};
  --effect-focus-highlight-spread: 3px;
  --effect-shadow-100-spread: 0px;
  --effect-shadow-100-x: 2px;
  --effect-shadow-100-y: 4px;
  --effect-shadow-100-blur: 8px;
  --effect-shadow-200-spread: 0px;
  --effect-shadow-200-x: 3px;
  --effect-shadow-200-y: 6px;
  --effect-shadow-200-blur: 10px;
  --effect-shadow-300-spread: 0px;
  --effect-shadow-300-x: 3px;
  --effect-shadow-300-y: 6px;
  --effect-shadow-300-blur: 12px;
  --layout-gutter: #{figma.$spacer-200};
  --layout-breakpoint: 352px;
  --layout-app-body-hero-spacing: #{figma.$spacer-400};
  --layout-app-body-hero-padding-bottom: #{figma.$spacer-1000};
  --layout-app-body-hero-padding-top: #{figma.$spacer-1000};
  --layout-app-body-margin-footer: #{figma.$spacer-800};
  --layout-app-body-section-spacing: #{figma.$spacer-500};
  --layout-app-body-section-margin-heading-bottom: #{figma.$spacer-250};
  --layout-app-body-section-padding-bottom: #{figma.$spacer-600};
  --layout-app-body-section-padding-top: #{figma.$spacer-600};
  --layout-app-section-spacer-100: #{figma.$spacer-150};
  --layout-app-section-spacer-200: #{figma.$spacer-250};
  --layout-app-section-spacer-300: #{figma.$spacer-400};
  --layout-app-section-spacer-400: #{figma.$spacer-500};
  --layout-app-section-spacer-500: #{figma.$spacer-700};
  --layout-content-max-width: 640px;
  --typography-body-100-letter-spacing: 0px;
  --typography-body-100-paragraph-spacing: 0px;
  --typography-body-100-size: 14px;
  --typography-body-100-line-height: 20px;
  --typography-body-200-letter-spacing: 0px;
  --typography-body-200-paragraph-spacing: 0px;
  --typography-body-200-size: 16px;
  --typography-body-200-line-height: 25px;
  --typography-body-300-letter-spacing: 0px;
  --typography-body-300-paragraph-spacing: 0px;
  --typography-body-300-size: 18px;
  --typography-body-300-line-height: 30px;
  --typography-body-weight-normal: 400;
  --typography-body-weight-bold: 600;
  --typography-button-body-weight: 600;
  --typography-button-overline-weight: 700;
  --typography-code-100-letter-spacing: 0px;
  --typography-code-100-paragraph-spacing: 0px;
  --typography-code-100-size: 13px;
  --typography-code-100-line-height: 20px;
  --typography-code-200-letter-spacing: 0px;
  --typography-code-200-paragraph-spacing: 0px;
  --typography-code-200-size: 15px;
  --typography-code-200-line-height: 25px;
  --typography-code-300-letter-spacing: 0px;
  --typography-code-300-paragraph-spacing: 0px;
  --typography-code-300-size: 18px;
  --typography-code-300-line-height: 30px;
  --typography-code-weight-normal: 400;
  --typography-code-weight-bold: 700;
  --typography-form-field-action-selector-paragraph-spacing: 0px;
  --typography-form-field-action-selector-letter-spacing: 1px;
  --typography-form-field-action-selector-line-height: 9px;
  --typography-form-field-action-selector-size: 11px;
  --typography-form-field-action-selector-weight: 700;
  --typography-form-field-label-paragraph-spacing: 0px;
  --typography-form-field-label-letter-spacing: 1px;
  --typography-form-field-label-size: 13px;
  --typography-form-field-label-line-height: 20px;
  --typography-form-field-label-weight: 400;
  --typography-form-field-support-100-letter-spacing: 0px;
  --typography-form-field-support-100-paragraph-spacing: 0px;
  --typography-form-field-support-100-size: 12px;
  --typography-form-field-support-100-line-height: 18px;
  --typography-form-field-support-200-letter-spacing: 0px;
  --typography-form-field-support-200-paragraph-spacing: 0px;
  --typography-form-field-support-200-size: 14px;
  --typography-form-field-support-200-line-height: 20px;
  --typography-form-field-support-weight-normal: 400;
  --typography-form-field-support-weight-bold: 600;
  --typography-heading-weight: 600;
  --typography-heading-100-letter-spacing: -1px;
  --typography-heading-100-paragraph-spacing: 0px;
  --typography-heading-100-size: 46px;
  --typography-heading-100-line-height: 59px;
  --typography-heading-200-letter-spacing: -1px;
  --typography-heading-200-paragraph-spacing: 0px;
  --typography-heading-200-size: 36px;
  --typography-heading-200-line-height: 47px;
  --typography-heading-300-letter-spacing: -0.5px;
  --typography-heading-300-paragraph-spacing: 0px;
  --typography-heading-300-size: 28px;
  --typography-heading-300-line-height: 37px;
  --typography-heading-400-letter-spacing: -0.5px;
  --typography-heading-400-paragraph-spacing: 0px;
  --typography-heading-400-size: 24px;
  --typography-heading-400-line-height: 32px;
  --typography-heading-500-letter-spacing: -0.5px;
  --typography-heading-500-paragraph-spacing: 0px;
  --typography-heading-500-size: 20px;
  --typography-heading-500-line-height: 30px;
  --typography-heading-600-letter-spacing: -0.5px;
  --typography-heading-600-paragraph-spacing: 0px;
  --typography-heading-600-size: 20px;
  --typography-heading-600-line-height: 30px;
  --typography-link-body-weight: 600;
  --typography-link-code-weight: 500;
  --typography-metric-paragraph-spacing: 0px;
  --typography-metric-letter-spacing: 1px;
  --typography-metric-size: 20px;
  --typography-metric-line-height: 30px;
  --typography-metric-weight: 700;
  --typography-overline-paragraph-spacing: 0px;
  --typography-overline-letter-spacing: 1px;
  --typography-overline-size: 13px;
  --typography-overline-line-height: 20px;
  --typography-overline-weight: 500;
  --typography-table-body-subtext-line-height: 18px;
  --typography-table-body-subtext-body-letter-spacing: 0px;
  --typography-table-body-subtext-body-paragraph-spacing: 0px;
  --typography-table-body-subtext-body-size: 12px;
  --typography-table-body-subtext-code-letter-spacing: 0px;
  --typography-table-body-subtext-code-paragraph-spacing: 0px;
  --typography-table-body-subtext-code-size: 12px;
  --typography-table-body-value-line-height: 20px;
  --typography-table-body-value-body-letter-spacing: 0px;
  --typography-table-body-value-body-paragraph-spacing: 0px;
  --typography-table-body-value-body-size: 14px;
  --typography-table-body-value-code-letter-spacing: 0px;
  --typography-table-body-value-code-paragraph-spacing: 0px;
  --typography-table-body-value-code-size: 13px;
  --typography-table-header-paragraph-spacing: 0px;
  --typography-table-header-letter-spacing: 1px;
  --typography-table-header-size: 12px;
  --typography-table-header-line-height: 20px;
  --typography-table-header-weight: 500;
}

@include device.gt-100 {
  :root {
    --component-footer-content-spacing: #{figma.$spacer-250};
    --component-modal-max-width: 520px;
    --component-modal-padding-content-y: #{figma.$spacer-400};
    --component-modal-spacing-text: #{figma.$spacer-200};
    --component-modal-spacing-actions: #{figma.$spacer-250};
    --component-modal-spacing-content: #{figma.$spacer-400};
    --component-navigation-content-spacing: #{figma.$spacer-250};
    --layout-gutter: #{figma.$spacer-400};
    --typography-heading-100-size: 55px;
    --typography-heading-100-line-height: 70px;
    --typography-heading-200-size: 46px;
    --typography-heading-200-line-height: 59px;
    --typography-heading-300-letter-spacing: -1px;
    --typography-heading-300-size: 36px;
    --typography-heading-300-line-height: 47px;
    --typography-heading-400-size: 28px;
    --typography-heading-400-line-height: 37px;
    --typography-heading-500-size: 24px;
    --typography-heading-500-line-height: 32px;
  }
}

@include device.eq-200 {
  :root {
    --component-gradient-size-200: 720px;
    --layout-breakpoint: 784px;
    --layout-app-body-hero-spacing: #{figma.$spacer-500};
    --layout-app-body-hero-padding-bottom: #{figma.$spacer-1200};
    --layout-app-body-hero-padding-top: #{figma.$spacer-1200};
    --layout-app-body-margin-footer: #{figma.$spacer-900};
    --layout-app-body-section-spacing: #{figma.$spacer-600};
    --layout-app-body-section-margin-heading-bottom: #{figma.$spacer-300};
    --layout-app-body-section-padding-bottom: #{figma.$spacer-700};
    --layout-app-body-section-padding-top: #{figma.$spacer-700};
    --layout-app-section-spacer-100: #{figma.$spacer-200};
    --layout-app-section-spacer-200: #{figma.$spacer-300};
    --layout-app-section-spacer-300: #{figma.$spacer-500};
    --layout-app-section-spacer-400: #{figma.$spacer-700};
    --layout-app-section-spacer-500: #{figma.$spacer-800};
  }
}

@include device.gt-200 {
  :root {
    --layout-app-body-hero-spacing: #{figma.$spacer-600};
    --layout-app-body-hero-padding-bottom: #{figma.$spacer-1400};
    --layout-app-body-hero-padding-top: #{figma.$spacer-1400};
    --layout-app-body-margin-footer: #{figma.$spacer-1000};
    --layout-app-body-section-spacing: #{figma.$spacer-700};
    --layout-app-body-section-margin-heading-bottom: #{figma.$spacer-400};
    --layout-app-body-section-padding-bottom: #{figma.$spacer-800};
    --layout-app-body-section-padding-top: #{figma.$spacer-800};
    --layout-app-section-spacer-100: #{figma.$spacer-200};
    --layout-app-section-spacer-200: #{figma.$spacer-400};
    --layout-app-section-spacer-300: #{figma.$spacer-600};
    --layout-app-section-spacer-400: #{figma.$spacer-800};
    --layout-content-max-width: 672px;
  }
}

@include device.eq-300 {
  :root {
    --component-gradient-size-200: 960px;
    --layout-app-section-spacer-500: #{figma.$spacer-900};
    --layout-breakpoint: 1024px;
  }
}

@include device.eq-400 {
  :root {
    --component-gradient-size-200: 1120px;
    --layout-app-section-spacer-500: #{figma.$spacer-1000};
    --layout-breakpoint: 1248px;
    --typography-heading-400-letter-spacing: 0px;
  }
}
