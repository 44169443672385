$max-width: 1264px;

//
// The sizes represent the min-width for that breakpoint.
//
$-breakpoint-100: 352px;
$-breakpoint-200: 784px;
$-breakpoint-300: 1024px;
$-breakpoint-400: 1248px;

@mixin eq-100 {
  @media (max-width: $-breakpoint-200 - 1px) {
    @content;
  }
}

@mixin eq-200 {
  @media (min-width: $-breakpoint-200) and (max-width: $-breakpoint-300 - 1px) {
    @content;
  }
}

@mixin eq-300 {
  @media (min-width: $-breakpoint-300) and (max-width: $-breakpoint-400 - 1px) {
    @content;
  }
}

@mixin eq-400 {
  @media (min-width: $-breakpoint-400) {
    @content;
  }
}

@mixin gt-100 { // 200, 300, 400
  @media (min-width: $-breakpoint-200) {
    @content;
  }
}

@mixin gt-200 { // 300, 400
  @media (min-width: $-breakpoint-300) {
    @content;
  }
}

@mixin lt-400 { // 300, 200, 100
  @media (max-width: $-breakpoint-400 - 1px) {
    @content;
  }
}

@mixin lt-300 { // 200, 100
  @media (max-width: $-breakpoint-300 - 1px) {
    @content;
  }
}
