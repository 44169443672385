@use "../device";
@use "../figma";
@use "../mixins";
@use "../typography";

.v2-footer {
  padding: figma.$spacer-500 var(--layout-gutter);
  background-color: figma.$component-footer-background;
}

.v2-footer,
.v2-footer__body,
.v2-footer__columns {
  @include mixins.vertical(figma.$spacer-400);
}

.v2-footer__newsletter {
  max-width: 404px;
}

/*p*/.v2-footer__text {
  margin: 0;

  @include typography.body-100;
  color: figma.$default-foreground-soft;
}

@include device.gt-100 {
  .v2-footer__columns {
    flex-direction: row;
  }
}

@include device.gt-200 {
  .v2-footer__body {
    flex-direction: row;
  }
}
