@use "../figma";
@use "../mixins";

.links-by-category {
  @include mixins.vertical(figma.$spacer-150);
}

/*ul*/.links-by-category__links {
  @include mixins.reset-list;
  @include mixins.vertical(figma.$spacer-150);
}

.links-by-category__link {
  display: flex;
}
