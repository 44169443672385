@use "../effects";
@use "../figma";

.v2-nav-menu {
  width: 200px;
  border-radius: 18px;

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  background-color: figma.$default-background-normal;
  box-shadow: effects.$box-shadow-200;

  &__section {
    padding: figma.$spacer-200 0;
    border-bottom: 1px solid figma.$default-border;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__item {
    .link {
      display: flex;
      justify-content: flex-end;
      padding: figma.$spacer-100 figma.$spacer-200;
    }

    background-color: figma.$default-background-normal;

    &--is-selected {
      background-color: figma.$component-navigation-link-background-selected;
    }

    &:hover {
      background-color: figma.$default-background-highlight;
    }
  }
}
