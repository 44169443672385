@use "../figma";
@use "../typography";

.input {
  appearance: none;
  outline: none;

  width: 100%;
  padding: 8px 16px;

  border-radius: 4px;
  border: 1px solid figma.$component-form-field-default-border-normal;

  color: figma.$component-form-field-default-foreground-value;
  background-color: figma.$component-form-field-default-background-normal;
  caret-color: figma.$constant-mono-500;
}

input::placeholder {
  color: figma.$component-form-field-default-foreground-placeholder;
}

.input:focus {
  border-color: figma.$component-form-field-default-border-focus;
  background-color: figma.$component-form-field-default-background-focus;
  box-shadow: 0px 0px 0px var(--effect-focus-highlight-spread) figma.$effect-focus-highlight-normal;
}

// Size

.input--100 {
  @include typography.code-100;
}

.input--200 {
  @include typography.code-200;
}

// Validity

.input--is-valid {
  border-color: figma.$component-form-field-default-border-success;
}

.input--is-invalid {
  border-color: figma.$component-form-field-default-border-error;
}

.input--is-invalid:focus {
  border-color: figma.$component-form-field-default-border-error;
  box-shadow: 0px 0px 0px var(--effect-focus-highlight-spread) figma.$effect-focus-highlight-error;
}

// Disabled

.input[disabled] {
  color: figma.$component-form-field-default-foreground-disabled;
}
