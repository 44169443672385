@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin inline-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@mixin horizontal($gap, $align-items: center) {
  display: flex;
  flex-direction: row;
  align-items: $align-items;
  gap: $gap;
}

@mixin inline-horizontal($gap, $align-items: center) {
  display: inline-flex;
  align-items: $align-items;
  gap: $gap;
}

@mixin vertical($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin max-content-width {
  width: 100%;
  max-width: var(--layout-content-max-width);
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
