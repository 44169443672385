@use "../figma";

.dialog::backdrop {
  background-color: figma.$component-modal-backdrop;
}

.dialog {
  padding: 0;
  border: 0;
  border-radius: var(--default-border-radius-300);
}

// Dialog transitions

.dialog {
  //
  // <dialog> elements initially have `display: none`. And,
  // when the dialog is opened it becomes `display: block`.
  //
  // However, this doesn't work well when you need to do
  // transitions. So we use an alternative approach to hide
  // the element. A combination of opacity and visibility.
  //
  // The one major downside is that we don't get the first
  // focusable element to be given the focus when the dialog
  // opens. That's something we'd have to manage ourselves.
  //
  //
  display: block;

  transform: translateY(24px);
  opacity: 0;
  visibility: hidden;

  transition-property: transform, opacity, visibility;
  transition-duration: var(--dialog-transition-duration, 120ms);
  transition-delay: 0ms;
  transition-timing-function: ease-in;
}

.dialog[open] {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;

  transition-timing-function: ease-out;
}
