@use "../figma";
@use "../mixins";
@use "../typography";

.field {
  @include mixins.vertical(figma.$spacer-75);
}

// Support Text

.field__support-text {
  margin: 0;

  @include typography.support-text;
  color: figma.$component-form-field-default-support-text-normal;
}

.field__support-text--is-valid {
  color: figma.$component-form-field-default-support-text-success;
}

.field__support-text--is-invalid {
  color: figma.$component-form-field-default-support-text-error;
}
