@use "../device";
@use "../figma";
@use "../mixins";
@use "../typography";

// Structure

.hero {
  @include mixins.horizontal(var(--layout-app-body-section-spacing));
  padding: var(--layout-app-body-hero-padding-top) 0 var(--layout-app-body-hero-padding-bottom) 0;
}

.hero__title, .hero__description, .hero__link {
  margin: 0;
}

.hero__content {
  @include mixins.vertical(figma.$spacer-100);
  @include mixins.max-content-width;
}

.hero__image {
  display: none;
}

@include device.gt-200 {
  .hero__image {
    display: block;
    margin-left: auto;
  }
}

// Typography

.hero__title {
  @include typography.heading-200;
}

.hero__description {
  @include typography.body-300;
}
