@use "../figma";
@use "../mixins";
@use "../typography";

.newsletter {
  @include mixins.vertical(figma.$spacer-150);
}

.newsletter__content {
  @include mixins.vertical(figma.$spacer-200);
}

/*p*/.newsletter__description {
  margin: 0;
  @include typography.body-100;
  color: figma.$default-foreground-normal;
}

.newsletter__form {
  @include mixins.horizontal(figma.$spacer-200);

  .field {
    width: 100%;
  }

  .input--100 {
    @include typography.body-100;
  }

  &:has(.field__support-text) {
    align-items: flex-start;
  }
}
