@use "../device";
@use "../effects";
@use "../figma";
@use "../mixins";

.page {
  @include mixins.vertical(0);
}

// Nav

.page__nav {
  margin-bottom: var(--layout-app-section-spacer-500);
}

.page__nav:has(.v2-nav-alt) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page__nav > .v2-nav-alt {
  width: 100%;
  max-width: device.$max-width;
}

.page__main {
  display: flex;
  justify-content: center;
}

.page:has(.page__main--centered) {
  height: 100%;
}

.page__main--centered {
  height: 100%;
  align-items: center;
}

.page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--layout-app-section-spacer-500);

  width: 100%;

  padding-bottom: var(--layout-app-section-spacer-500);
  overflow: clip;
}

.page__content > div {
  padding-left: var(--layout-gutter);
  padding-right: var(--layout-gutter);
  width: 100%;
  max-width: device.$max-width;
}


// Footer

.page__footer:has(.v2-footer) {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: figma.$component-footer-background;
}

.page__footer > .v2-footer {
  width: 100%;
  max-width: device.$max-width;
}


// Page scrolling and the navigation

.page--scrolled-down {
  .page__nav {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  // Rearrange things a bit
  .v2-nav-alt {
    border-bottom: none;
    box-shadow: none;
  }

  .page__nav {
    background-color: figma.$default-background-normal;
    border-bottom: 1px solid figma.$default-border;
    box-shadow: effects.$box-shadow-100;
  }
}

// Staking Page

.page--staking {
  .metrics {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .metrics + .cards {
    margin-top: var(--layout-app-body-section-padding-top);
  }
}


// Not Found Page

.page--not-found {
  .page__content {
    gap: var(--layout-app-section-spacer-300);
  }
}
