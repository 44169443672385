@use "sass:list";
@use "../mixins";

.icon {
  @include mixins.inline-center;

  svg {
    @include mixins.inline-center;
  }
}

$-sizes: (
  50: 10px 8px,
  75: 16px 12px,
  100: 20px 16px,
  200: 25px 20px,
  300: 30px 24px,
  400: 38px 32px
);

@each $size, $dimensions in $-sizes {
  .fa-#{$size} {
    font-size: list.nth($dimensions, 2);
  }

  .icon--size-#{$size} {
    width: list.nth($dimensions, 1);
    height: list.nth($dimensions, 1);

    & > svg {
      width: list.nth($dimensions, 2);
      height: list.nth($dimensions, 2);
    }
  }
}
