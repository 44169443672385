@use "../device";
@use "../effects";
@use "../figma";
@use "../mixins";

$nav-container-max-width: device.$max-width;
$nav-background-color: figma.$default-background-normal;
$nav-border-color: figma.$default-border;
$nav-box-shadow: effects.$box-shadow-100;

//
// Part 1
//
// Style the dropdown menu.
//

.nav, .nav__bar {
  position: relative;
}

.nav__bar {
  z-index: 2;
}

.nav__dropdown-menu {
  background-color: $nav-background-color;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  opacity: 0;
  transition: transform 160ms 0ms ease-out, opacity 160ms 0ms ease-out;
}

//
// Part 2
//
// These styles are for devices 100 and 200.
//

.nav {
  --nav-height: calc(var(--component-navigation-content-height) + 2 * var(--component-navigation-padding-y));
  --nav-container-vertical-padding: var(--component-navigation-padding-y);
  --nav-container-horizontal-padding: var(--layout-gutter);
  --nav-content-spacing: var(--component-navigation-content-spacing);

  display: flex;
  align-items: center;

  background-color: $nav-background-color;
  box-shadow: $nav-box-shadow;
}

.nav__bar {
  @include mixins.horizontal(var(--nav-content-spacing));

  height: var(--nav-height);
  width: 100%;
  border-bottom: 1px solid $nav-border-color;

  padding: var(--nav-container-vertical-padding) var(--nav-container-horizontal-padding);

  background-color: $nav-background-color;
}

.nav__inline-menu {
  display: none;
}

.nav__toggle {
  margin-left: auto;
}

//
// Part 3
//
// These styles are for devices 300 and 400.
//

@include device.gt-200 {
  .nav__bar {
    max-width: $nav-container-max-width;

    border-bottom: 0;
    height: calc(var(--nav-height) - 1px);
  }

  .nav {
    justify-content: center;
    border-bottom: 1px solid $nav-border-color;
  }

  .nav__inline-menu {
    display: block;
    width: 100%;
  }

  .nav__toggle {
    display: none;
  }
}

//
// Part 4
//
// Determine when and how the dropdown menu is shown.
//

@include device.lt-300 {
  .nav--is-open {
    box-shadow: none;

    .nav__dropdown-menu {
      opacity: 1;
      transform: translateY(100%);

      box-shadow: $nav-box-shadow;
    }
  }
}
