@use "../figma";

.checkbox-field {
  display: flex;
}

.checkbox-field--size-100 {
  gap: figma.$spacer-125;
}

.checkbox-field--size-200 {
  gap: figma.$spacer-150;
}

.checkbox-field:not(:has(:disabled)) {
  cursor: pointer;
}

.checkbox-field:has(:disabled) {
  cursor: not-allowed;
}
