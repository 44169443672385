@use "../figma";
@use "../mixins";
@use "../typography";

.tagline {
  @include typography.body-200;
  color: figma.$default-foreground-soft;

  @include mixins.vertical(figma.$spacer-150);
  @include mixins.max-content-width;
}

.tagline__logo {
  display: flex;
}

.tagline__text {
  margin: 0;
}
