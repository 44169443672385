/* Light */
/* color */
$constant-transparent: #ffffff00;
$constant-mono-0: #ffffff;
$constant-mono-10: #fcfcfc;
$constant-mono-100: #e6e6e6;
$constant-mono-1000: #000000;
$constant-mono-150: #d9d9d9;
$constant-mono-200: #cccccc;
$constant-mono-300: #b3b3b3;
$constant-mono-400: #999999;
$constant-mono-50: #f2f2f2;
$constant-mono-500: #808080;
$constant-mono-600: #666666;
$constant-mono-70: #ededed;
$constant-mono-700: #4d4d4d;
$constant-mono-800: #333333;
$constant-mono-900: #191919;
$default-blue-100: #d6e9fc;
$default-blue-200: #aed3f8;
$default-blue-300: #85bdf5;
$default-blue-400: #5da7f1;
$default-blue-50: #ecf5fe;
$default-blue-500: #3491ee;
$default-blue-600: #1379df;
$default-blue-700: #0f61b2;
$default-blue-800: #0b4886;
$default-blue-900: #073059;
$default-blue-950: #04182d;
$default-blue-25-0: #f5fafe00;
$default-blue-25-100: #f5fafe;
$default-green-100: #d2f8ee;
$default-green-200: #a5f1de;
$default-green-300: #78ebcd;
$default-green-400: #4ce4bd;
$default-green-50: #e5fbf5;
$default-green-500: #21dbab;
$default-green-600: #1bb78f;
$default-green-700: #169272;
$default-green-800: #106e56;
$default-green-900: #0b4939;
$default-green-950: #05251d;
$default-green-25-0: #edfcf800;
$default-green-25-100: #edfcf8;
$default-orange-100: #fce9d6;
$default-orange-200: #f8d3ae;
$default-orange-300: #f5bd85;
$default-orange-400: #f1a75d;
$default-orange-50: #fdf2e7;
$default-orange-500: #ee9134;
$default-orange-600: #df7913;
$default-orange-700: #b2610f;
$default-orange-800: #86490b;
$default-orange-900: #593007;
$default-orange-950: #2d1804;
$default-orange-25-0: #fef7f100;
$default-orange-25-100: #fef7f1;
$default-purple-100: #e9d6fc;
$default-purple-200: #d3aef8;
$default-purple-300: #bd85f5;
$default-purple-400: #a75df1;
$default-purple-50: #f2e7fd;
$default-purple-500: #9134ee;
$default-purple-600: #7913df;
$default-purple-700: #610fb2;
$default-purple-800: #480b86;
$default-purple-900: #300759;
$default-purple-950: #18042d;
$default-purple-25-0: #f7f1fe00;
$default-purple-25-100: #f7f1fe;
$default-red-100: #fcd6d6;
$default-red-200: #f8aeae;
$default-red-300: #f58585;
$default-red-400: #f15d5d;
$default-red-50: #fde7e7;
$default-red-500: #ee3434;
$default-red-600: #df1313;
$default-red-700: #b20f0f;
$default-red-800: #860b0b;
$default-red-900: #590707;
$default-red-950: #2d0404;
$default-red-25-0: #fef1f100;
$default-red-25-100: #fef1f1;
$default-stone-10: #fdfdfb;
$default-stone-100: #ece5cf;
$default-stone-200: #e3d8b6;
$default-stone-300: #d9cb9e;
$default-stone-400: #ccb97c;
$default-stone-50: #f6f2e7;
$default-stone-500: #bfa75b;
$default-stone-600: #a89042;
$default-stone-700: #877335;
$default-stone-75: #f4efe1;
$default-stone-800: #655628;
$default-stone-900: #433a1a;
$default-stone-950: #221d0d;
$default-stone-25-0: #fbf9f400;
$default-stone-25-100: #fbf9f4;
$default-yellow-100: #fff3d8;
$default-yellow-200: #fee6b2;
$default-yellow-300: #fada8b;
$default-yellow-400: #f5cf63;
$default-yellow-50: #fff6e0;
$default-yellow-500: #efc334;
$default-yellow-550: #deae12;
$default-yellow-600: #c39f2e;
$default-yellow-700: #997d28;
$default-yellow-800: #715d21;
$default-yellow-900: #4b3e1a;
$default-yellow-950: #282211;
$default-yellow-25-0: #fff9eb00;
$default-yellow-25-100: #fff9eb;
