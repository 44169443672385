@use "../figma";

$track-height: 3px;
$control-height: 10px;

.range {
  --range-track-width: 50%;
}

// Structure

.range__track {
  width: var(--range-track-width);
  height: $track-height;

  background-color: figma.$component-form-field-action-background-active;
}

.range__input {
  appearance: none;
  margin: 0;

  width: 100%;
  height: $control-height;

  cursor: pointer;

  background-color: transparent;

  &::-webkit-slider-thumb {
    appearance: none;

    width: $control-height;
    height: $control-height;

    background-color: transparent;
  }

  &::-moz-range-thumb {
    border: 0;
    border-radius: 0;

    width: $control-height;
    height: $control-height;

    background-color: transparent;
  }
}

// Position

.range {
  position: relative;
  width: 100%;
  height: $control-height;
}

.range__track {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.range__input {
  position: absolute;
}

// Transition

.range__track {
  transition: width 240ms ease-out;
}

// Modifiers

.range--is-invalid .range__track {
  background-color: transparent;
}

// Useful for debugging purposes

// .range__input {
//   background-color: pink;
//   opacity: 0.5;
// }
