@use "../figma";
@use "../mixins";
@use "../typography";

.label {
  @include typography.label;
  white-space: nowrap;

  color: figma.$component-form-field-label;
}

// Required

.label--is-required {
  @include mixins.inline-horizontal(4px);
}

.label--is-required .icon {
  color: figma.$component-form-field-required;
}
