/* Default */
/* color */
$default-border: $constant-mono-70;
$default-ghost: $constant-mono-100;
$default-background-alternate: $constant-mono-50;
$default-background-highlight: $default-blue-50;
$default-background-normal: $constant-mono-0;
$default-foreground-error: $default-red-600;
$default-foreground-highlight: $constant-mono-1000;
$default-foreground-inverse: $constant-mono-0;
$default-foreground-normal: $constant-mono-1000;
$default-foreground-soft: $constant-mono-600;
$default-foreground-success: $default-green-700;

$component-alert-default-background: $default-background-normal;
$component-alert-default-border: $default-border;
$component-alert-default-foreground-description: $default-foreground-soft;
$component-alert-default-foreground-title: $default-foreground-normal;
$component-alert-error-background: $default-red-50;
$component-alert-error-border: $default-red-100;
$component-alert-error-foreground-description: $default-red-800;
$component-alert-error-foreground-title: $default-red-900;
$component-alert-info-background: $default-blue-50;
$component-alert-info-border: $default-blue-100;
$component-alert-info-foreground-description: $default-blue-800;
$component-alert-info-foreground-title: $default-blue-900;
$component-alert-warning-background: $default-yellow-50;
$component-alert-warning-border: $default-yellow-100;
$component-alert-warning-foreground-description: $default-yellow-800;
$component-alert-warning-foreground-title: $default-yellow-900;
$component-badge-green-background: $default-green-200;
$component-badge-green-foreground: $default-green-900;
$component-badge-mono-background: $constant-mono-200;
$component-badge-mono-foreground: $constant-mono-800;
$component-badge-orange-background: $default-orange-200;
$component-badge-orange-foreground: $default-orange-900;
$component-badge-purple-background: $default-purple-200;
$component-badge-purple-foreground: $default-purple-900;
$component-badge-red-background: $default-red-200;
$component-badge-red-foreground: $default-red-900;
$component-button-danger-foreground: $constant-mono-0;
$component-button-danger-background-hover: $default-red-500;
$component-button-danger-background-normal: $default-red-600;
$component-button-danger-background-pressed: $default-red-700;
$component-button-disabled-background: $constant-mono-100;
$component-button-disabled-foreground: $constant-mono-500;
$component-button-nav-menu-close-foreground: $default-red-600;
$component-button-nav-menu-close-background-hover: $default-red-25-100;
$component-button-nav-menu-close-background-normal: $default-red-50;
$component-button-nav-menu-close-background-pressed: $default-red-100;
$component-button-nav-menu-open-foreground: $default-blue-600;
$component-button-nav-menu-open-background-hover: $default-blue-25-100;
$component-button-nav-menu-open-background-normal: $default-blue-50;
$component-button-nav-menu-open-background-pressed: $default-blue-100;
$component-button-primary-foreground: $constant-mono-0;
$component-button-primary-background-hover: $default-blue-500;
$component-button-primary-background-normal: $default-blue-600;
$component-button-primary-background-pressed: $default-blue-700;
$component-footer-background: $constant-mono-50;
$component-form-field-label: $constant-mono-800;
$component-form-field-required: $default-yellow-500;
$component-form-field-action-background-active: $default-green-700;
$component-form-field-action-background-normal: $constant-mono-0;
$component-form-field-action-border-active: $default-green-700;
$component-form-field-action-border-normal: $constant-mono-100;
$component-form-field-action-foreground-active: $constant-mono-0;
$component-form-field-action-foreground-hover: $default-blue-500;
$component-form-field-action-foreground-normal: $default-blue-600;

$component-form-field-default-background-disabled: $constant-mono-100;
$component-form-field-default-background-focus: $constant-mono-0;
$component-form-field-default-background-normal: $constant-mono-10;
$component-form-field-default-border-error: $default-red-600;
$component-form-field-default-border-focus: $default-blue-600;
$component-form-field-default-border-normal: $constant-mono-100;
$component-form-field-default-border-success: $default-green-700;
$component-form-field-default-foreground-disabled: $constant-mono-600;
$component-form-field-default-foreground-placeholder: $constant-mono-400;
$component-form-field-default-foreground-value: $constant-mono-1000;
$component-form-field-default-suffix-background: $constant-mono-100;
$component-form-field-default-suffix-foreground: $constant-mono-700;
$component-form-field-default-support-text-error: $default-red-600;
$component-form-field-default-support-text-normal: $constant-mono-600;
$component-form-field-default-support-text-success: $default-green-700;

$component-form-field-checkbox-box-background-disabled: $component-form-field-default-background-disabled;
$component-form-field-checkbox-box-background-checked-focus: $default-blue-500;
$component-form-field-checkbox-box-background-checked-normal: $default-blue-500;
$component-form-field-checkbox-box-background-checked-pressed: $default-blue-600;
$component-form-field-checkbox-box-background-unchecked-focus: $component-form-field-default-background-focus;
$component-form-field-checkbox-box-background-unchecked-normal: $component-form-field-default-background-normal;
$component-form-field-checkbox-box-background-unchecked-pressed: $constant-mono-50;
$component-form-field-checkbox-check-disabled: $component-form-field-default-foreground-disabled;
$component-form-field-checkbox-check-enabled: $default-blue-100;
$component-form-field-dropdown-caret: $constant-mono-300;
$component-form-field-switch-handle-background-disabled: $constant-mono-200;
$component-form-field-switch-handle-background-off: $constant-mono-100;
$component-form-field-switch-handle-background-on: $default-blue-100;
$component-form-field-switch-track-background-disabled: $constant-mono-100;
$component-form-field-switch-track-background-off: $constant-mono-500;
$component-form-field-switch-track-background-on: $default-blue-500;
$component-fragment-blue-100: $default-blue-25-100;
$component-fragment-green-100: $default-green-25-100;
$component-fragment-orange-100: $default-orange-25-100;
$component-fragment-purple-100: $default-purple-25-100;
$component-fragment-red-100: $default-red-25-100;
$component-fragment-yellow-100: $default-yellow-25-100;
$component-gradient-blue-100-from: $default-blue-50;
$component-gradient-blue-100-to: $default-blue-25-0;
$component-gradient-green-100-from: $default-green-50;
$component-gradient-green-100-to: $default-green-25-0;
$component-gradient-orange-100-from: $default-orange-50;
$component-gradient-orange-100-to: $default-orange-25-0;
$component-gradient-purple-100-from: $default-purple-50;
$component-gradient-purple-100-to: $default-purple-25-0;
$component-gradient-red-100-from: $default-red-50;
$component-gradient-red-100-to: $default-red-25-0;
$component-gradient-yellow-100-from: $default-yellow-50;
$component-gradient-yellow-100-to: $default-yellow-25-0;
$component-gradient-yellow-200-from: $default-yellow-300;
$component-gradient-yellow-200-to: $default-yellow-100;
$component-link-danger-hover: $default-red-500;
$component-link-danger-normal: $default-red-600;
$component-link-danger-pressed: $default-red-700;
$component-link-default-normal-foreground-hover: $default-foreground-normal;
$component-link-default-normal-foreground-normal: $default-foreground-normal;
$component-link-default-normal-foreground-pressed: $default-foreground-normal;
$component-link-default-soft-foreground-hover: $constant-mono-500;
$component-link-default-soft-foreground-normal: $constant-mono-600;
$component-link-default-soft-foreground-pressed: $constant-mono-700;
$component-link-disabled-foreground: $constant-mono-500;
$component-link-primary-foreground-hover: $default-blue-500;
$component-link-primary-foreground-normal: $default-blue-600;
$component-link-primary-foreground-pressed: $default-blue-700;
$component-loading-spinner-background: $constant-mono-400;
$component-loading-spinner-foreground: $constant-mono-100;
$component-logo-foreground: $constant-mono-1000;
$component-logo-mark: $default-red-500;
$component-modal-backdrop: #0000000a;
$component-navigation-link-background-selected: $constant-mono-50;
$component-placeholder-background: $constant-mono-200;
$component-placeholder-foreground: $constant-mono-500;
$component-table-border: $default-border;
$component-table-cell-body-background: $default-background-normal;
$component-table-cell-default-foreground-error: $default-foreground-error;
$component-table-cell-default-foreground-subtext: $default-foreground-soft;
$component-table-cell-default-foreground-success: $default-foreground-success;
$component-table-cell-default-foreground-value-normal: $default-foreground-normal;
$component-table-cell-default-foreground-value-soft: $default-foreground-soft;
$component-table-cell-foot-background: $constant-mono-10;
$component-table-cell-head-background: $constant-mono-10;
$component-table-cell-head-foreground-normal: $default-foreground-soft;
$component-table-cell-head-foreground-sort-active: $default-foreground-highlight;
$component-table-cell-head-foreground-sort-inactive: $constant-mono-150;
$effect-focus-highlight-error: #f58585a6;
$effect-focus-highlight-normal: #85bdf5a6;
$effect-focus-highlight-success: #78ebcda6;
$effect-shadow-100: #0000000a;
$effect-shadow-200: #0000000f;
$effect-shadow-300: #00000014;
