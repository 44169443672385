@use "../effects";
@use "../figma";

.v2-nav-menu-alt {
  width: 200px;
  border-radius: 18px;

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  background-color: figma.$default-background-normal;
  box-shadow: effects.$box-shadow-200;

  &__toggle {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  &__section {
    padding: figma.$spacer-200 0;
    border-bottom: 1px solid figma.$default-border;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__item {
    background-color: figma.$default-background-normal;

    & > * {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: figma.$spacer-100 figma.$spacer-200;
    }

    &--is-selected {
      background-color: figma.$component-navigation-link-background-selected;
    }

    &:hover {
      background-color: figma.$default-background-highlight;
    }

    &--is-inert:hover {
      background-color: figma.$default-background-normal;
    }
  }
}

.v2-nav-menu-alt__item--is-selected.v2-nav-menu-alt__item--is-inert:hover {
  background-color: figma.$component-navigation-link-background-selected;
}
