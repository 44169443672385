@use "../figma";
@use "../mixins";
@use "../typography";

$button-gap: figma.$spacer-50;
$button-color-primary-background: figma.$component-button-primary-background-normal;
$button-color-primary-background-hover: figma.$component-button-primary-background-hover;
$button-color-primary-background-focus: $button-color-primary-background;
$button-color-primary-background-active: figma.$component-button-primary-background-pressed;
$button-color-primary-foreground: figma.$component-button-primary-foreground;
$button-color-disabled-background: figma.$component-button-disabled-background;
$button-color-disabled-foreground: figma.$component-button-disabled-foreground;
$button-box-shadow: 0 0 0 var(--effect-focus-highlight-spread) figma.$effect-focus-highlight-normal;
$button-100-horizontal-padding: figma.$spacer-200;
$button-100-vertical-padding: figma.$spacer-100;
$button-200-horizontal-padding: figma.$spacer-250;
$button-200-vertical-padding: figma.$spacer-100;
$button-300-horizontal-padding: figma.$spacer-250;
$button-300-vertical-padding: figma.$spacer-125;

.button {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  outline: none;

  @include mixins.inline-horizontal($button-gap);
  flex-wrap: nowrap;
  white-space: nowrap;

  cursor: pointer;
}

.button[disabled] {
  cursor: not-allowed;
}

// Color

.button--color-primary {
  background-color: $button-color-primary-background;
  color: $button-color-primary-foreground;

  &:hover {
    background-color: $button-color-primary-background-hover;
  }

  &:focus {
    background-color: $button-color-primary-background-focus;
    box-shadow: $button-box-shadow;
  }

  &:active {
    background-color: $button-color-primary-background-active;
  }
}

.button--color-disabled {
  background-color: $button-color-disabled-background;
  color: $button-color-disabled-foreground;
}

// Size/Typography

.button--100 {
  @include typography.body-100(var(--typography-button-body-weight));

  padding: $button-100-vertical-padding $button-100-horizontal-padding;
  border-radius: 20px;
}

.button--200 {
  @include typography.body-200(var(--typography-button-body-weight));

  padding: $button-200-vertical-padding $button-200-horizontal-padding;
  border-radius: 20.5px;
}

.button--300 {
  @include typography.body-300(var(--typography-button-body-weight));

  padding: $button-300-vertical-padding $button-300-horizontal-padding;
  border-radius: 25px;
}

// Loading

.button--loading {
  display: flex;
  justify-content: center;
  width: 160px;
}
